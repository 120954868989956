<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2197_5442" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_2197_5442)">
      <path d="M16.6142 5C16.0016 5 15.3891 5.23516 14.924 5.70011L5.67959 14.9445C5.60193 15.0217 5.55028 15.1211 5.53197 15.229L5.00681 18.3907C4.97958 18.5582 5.03501 18.7285 5.15552 18.8479C5.27604 18.9673 5.44673 19.0211 5.61401 18.9924L8.77571 18.4728C8.88365 18.4545 8.98304 18.4028 9.0602 18.3252L18.3046 9.08073C19.2347 8.15057 19.2347 6.6303 18.3046 5.70023C17.8395 5.23527 17.227 5.00012 16.6143 5.00012L16.6142 5ZM16.6142 6.04481C16.9551 6.04481 17.2946 6.17815 17.5606 6.44407C18.0926 6.97607 18.0926 7.80474 17.5606 8.33674L16.8824 9.015L14.9897 7.11695L15.6624 6.44407C15.9285 6.17813 16.2734 6.04481 16.6142 6.04481H16.6142ZM14.2457 7.86082L16.1384 9.75899L8.71007 17.1873L6.81201 15.2948L14.2457 7.86082ZM6.40729 16.3722L7.63256 17.5975L6.16652 17.8383L6.40729 16.3722Z" fill="#1E70BB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconEdit"
}
</script>

<style scoped>

</style>